<template>
  <div class="container">
    <Breadcrumb :breadcrumb-list="[ { 'name': 'Admin Dashboard', 'link': '/admin' },{ 'name': 'Delayed Payments', link: '/admin/payment' },{ 'name': 'Pending' } ]"/>
    <div class="main-container">
      <h1 class="mb-4 pb-1"><strong>Delayed Payments: Pending</strong></h1>
      <div class="container">
        <div v-if="payments.length === 0 && !pageLoading">
          <b-alert show variant="success" dismissible>
            No payments are currently being delayed
          </b-alert>
        </div>
        <div class="col-xl-12 mb-0 content-box" v-else-if="!pageLoading">
          <div class="pt-2 overflow-auto">
            <div class="col-xl-12 box-title border-bottom mb-3 ">
              <p class="mt-3">Total Delayed Payments: {{ payments.length }}</p>
            </div>
            <p class="text-right small">*To view payment in stripe, click on payment type</p>
            <table
              class="table table-striped table-bordered add-manage-table table demo footable-loaded footable"
              data-filter="#filter" data-filter-text-only="true">
              <thead>
              <tr>
                <th>Order #</th>
                <th>Task name</th>
                <th>Price</th>
                <th>Payment type</th>
                <th>Buyer username</th>
                <th>Buyer IP</th>
                <th>Buyer join date</th>
                <th>Seller username</th>
                <th>Seller IP</th>
                <th>Seller join date</th>
                <th>Date Purchased</th>
                <th>Date Completed</th>
                <th>Payout</th>
                <th>Reason</th>
                <th>Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(payment, index) in payments" :key="index" class="mb-md-0 mb-2">
                <td v-if="roleOrderDetails">
                  <router-link class="text-dark" :to="'/admin/orders/' + payment.order.hash">{{ payment.order.hash }}</router-link>
                </td>
                <td v-else>{{ payment.hash }}</td>
                <td>
                  <router-link class="text-dark" :to="'/' + payment.order.seller_username + '/' + payment.order.items[0].gig_slug">
                    {{ payment.order.items[0].gig_title }}
                  </router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/' + payment.order.seller_username + '/' + payment.order.items[0].gig_slug">
                    ${{ (payment.order.price_total.toFixed(2) !== '0.00') ? payment.order.price_total.toFixed(2) : payment.order.price_subtotal.toFixed(2) }}
                  </router-link>
                </td>
                <td>
                  <a v-if="payment.order.payments[0].payment_gateway.hash === 'stripe'" class="text-dark"
                     :href="'https://dashboard.stripe.com/payments/' +  payment.order.payments[0].reference" target="_blank">
                    {{ payment.order.payments[0].payment_gateway.display_name }}
                  </a>
                  <router-link v-else class="text-dark" :to="'/admin/orders/' + payment.order.hash">
                    {{ payment.order.payments[0].payment_gateway.display_name }}
                  </router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/users/' + payment.order.buyer_user.hash">
                    {{ payment.order.buyer_username }} <br> <small>Delayed: {{ payment.stats.buyer_username_delayed_payments }}</small>
                  </router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/orders/' + payment.order.hash">{{ payment.order.buyer_ip }} <br> <small>Delayed: {{ payment.stats.buyer_ip_delayed_payments }}</small></router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/orders/' + payment.order.hash">{{ date(payment.order.buyer_user.date_created, true) }}</router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/users/' + payment.order.seller_user.hash">
                    {{ payment.order.seller_username }} <br> <small>Delayed: {{ payment.stats.seller_username_delayed_payments }}</small>
                  </router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/orders/' + payment.order.hash">{{ payment.order.seller_ip }} <br> <small>Delayed: {{ payment.stats.seller_ip_delayed_payments }}</small></router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/orders/' + payment.order.hash">{{ date(payment.order.seller_user.date_created, true) }}</router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/orders/' + payment.order.hash">{{ date(payment.order.date_created) }}</router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/orders/' + payment.order.hash">{{ date(payment.order.date_completed) }}</router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/orders/' + payment.order.hash">{{ timeLeftUntilPayout(payment.order.date_completed) }}</router-link>
                </td>
                <td>
                  <router-link class="text-dark" :to="'/admin/orders/' + payment.order.hash">
                    {{ !payment.order.delay_payment_reason[0] ? 'Unknown' : payment.order.delay_payment_reason.join(', ') }}
                  </router-link>
                </td>
                <td>
                  <b-button variant="primary" @click="approveDeclinePayment(payment.order.hash, 1)" block>Approve Payment</b-button>
                  <b-button variant="danger" @click="approveDeclinePayment(payment.order.hash, 0)" block>Decline Payment</b-button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="mx-auto py-5">
          <b-spinner large label="Large Spinner"></b-spinner>
          <div>Loading Payments ...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '../../../apiService'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AdminPaymentPending',
  components: { Breadcrumb },
  data () {
    return {
      pageLoading: true,
      payments: [],
      roleOrderDetails: false,
      completedPaymentsHashes: []
    }
  },
  mounted () {
    this.getPayments()
  },
  methods: {
    ...mapActions([
      'setCompletedDelayedPayments'
    ]),
    getPayments () {
      this.pageLoading = true
      apiService.getDelayedPaymentsAdmin().then(response => {
        let vm = this
        this.payments = response.data.data.filter(function (val) {
          return !vm.$store.getters.hasPaymentBeenCompleted(val.order.hash)
        })
        this.pageLoading = false
        this.roleOrderDetails = this.$store.getters.hasRole('ROLE_ADMIN_VIEW_ORDER_DETAILS')
      }).catch(() => {
      })
    },
    date (date, includeTime = false) {
      if (date === null) {
        return 'NA'
      }
      const newDate = new Date(date)
      return includeTime ? newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString() : newDate.toLocaleDateString()
    },
    timeLeftUntilPayout (date) {
      const currentDate = new Date()
      const completedDate = new Date(date)
      currentDate.setDate(currentDate.getDate() - 5)

      let duration = this.moment.duration(this.moment(completedDate, 'DD/MM/YYYY HH:mm:ss').diff(this.moment(currentDate, 'DD/MM/YYYY HH:mm:ss')))

      if (duration.days() > 1) {
        return duration.days() + ' days left'
      } else if (duration.hours() > 1) {
        return duration.hours() + ' hrs left'
      } else {
        return duration.minutes() + ' minutes left'
      }
    },
    approveDeclinePayment (hash, value) {
      let vm = this
      this.$swal({
        title: value ? 'Approve Payment' : 'Decline Payment',
        text: value ? 'By approving payment seller will receive ETN' : 'By decline payment buyer will receive a refund',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancel',
        showCloseButton: true,
        showLoaderOnConfirm: true,
        html: !value ? '' +
          '<input type="checkbox" value="1" id="swal2-checkbox-refund"> <label for="swal2-checkbox-refund" class="swal2-label">Refund buyer?</label>' +
          '<textarea class="swal2-textarea" id="swal2-textarea-message" placeholder="Type your decline message here..." style="display: flex;"></textarea>' : '',
        preConfirm: (result) => {
          let payload
          if (!value) {
            const message = document.getElementById('swal2-textarea-message').value
            const refund = document.getElementById('swal2-checkbox-refund').checked ? 1 : 0
            if (message.length < 1) {
              this.$swal.showValidationMessage('Message can not be left empty')
              return false
            }

            payload = {
              'action': 0,
              'refund': refund,
              'message': message
            }
          } else {
            payload = {
              'action': 1
            }
          }
          return apiService.approveDeclinePayment(hash, payload).then(() => {
          }).catch((error) => {
            this.$swal.showValidationMessage(
              error.response.data.error_message
            )
          })
        }
      }).then((result) => {
        if (!result.dismiss) {
          this.$swal({
            title: 'Success',
            text: value ? 'Payment has been approved' : 'Payment has been declined',
            type: 'success',
            confirmButtonText: 'Close'
          }).then(() => {
            let completed = vm.getPaymentsBeenCompleted
            completed.push(hash)
            vm.setCompletedDelayedPayments(completed)
            vm.$vnode.child.getPayments()
          })
        }
      })
    }
  },
  computed: {
    ...mapGetters(['getPaymentsBeenCompleted'])
  }
}
</script>

<style lang="scss" scoped>
.content-box {
  overflow: unset !important;
}

.table {
  font-size: 10px;
  overflow-x: auto;
  @include md-up {
    overflow: hidden;
  }
  @include lg-up {
    font-size: 13px;
  }
}

.container {
  max-width: 100%;
}
</style>
