<template>
    <article class="">
        <a class="card text-secondary" @click="$router.push(route)">
            <div class="bg-dark p-4">
                <img v-if="type === 'img'" class="img-fluid" v-lazy="getImg(image)">
                <i v-else class="fa-5x p-2 text-white" :class="[image, iconType]" aria-hidden="true"/>
            </div>
            <div class="card-body">
                <h5 class="mb-0 pb-0">{{title}}</h5>
            </div>
        </a>
    </article>
</template>

<script>
export default {
  name: 'AdminCards',
  props: {
    route: String,
    image: String,
    title: String,
    type: {
      type: String,
      required: false,
      default: 'img'
    },
    iconType: {
      type: String,
      required: false,
      default: 'fa-solid'
    }
  },
  methods: {
    getImg (pic) {
      return require('../../assets/' + pic)
    }
  }
}
</script>
