<template>
  <div class="container">
    <Breadcrumb class="bg-transparent" :breadcrumbList="[
        { 'name': 'Admin Dashboard', 'link': $router.resolve({ name: 'admin' }).href },
        { 'name': 'Delayed Payments' }
      ]"/>
    <div class="main-container">
      <h1 class=" mb-2"><strong>Delayed Payments</strong></h1>
      <div class="row mt-5 justify-content-center" v-if=" this.$store.getters.hasRole('ROLE_ADMIN_REPORT')">
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4" >
          <AdminCards type="svg" image="fa-hourglass-start" title="Pending" :route="'/admin/payment/pending'"/>
        </div>
        <div class="col-12 col-sm-6 col-md-3 col-lg-3 mb-4 mb-sm-4 " >
          <AdminCards type="svg" icon-type="fa-regular" image="fa-circle-check" title="Processed" :route="'/admin/payment/processed'"/>
        </div>
      </div>
      <div v-else>
        <b-alert variant="danger" fade show>If you feel like you not seeing the right information, please try and re-login</b-alert>
      </div>
    </div>
  </div>
</template>

<script>
import AdminCards from '../../../components/Admin/AdminCards'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'

export default {
  name: 'AdminPayment',
  components: { Breadcrumb, AdminCards }
}
</script>

<style lang="scss" scoped>
  .content-box {
    overflow: unset !important;
  }
  .table {
    font-size: 10px;
    overflow-x: auto;
    @include md-up {
      overflow: hidden;
    }
    @include lg-up {
      font-size: 13px;
    }
  }
  .container {
    max-width: 100%;
  }
</style>
