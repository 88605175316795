<template>
  <div class="container">
    <Breadcrumb :breadcrumb-list="[ { 'name': 'Admin Dashboard', 'link': '/admin' },{ 'name': 'Delayed Payments', link: '/admin/payment' },{ 'name': 'Processed' } ]"/>
    <div class="main-container">
      <h1 class="mb-4 pb-1"><strong>Delayed Payments: Processed</strong></h1>
      <div class="container">
        <div v-if="total === 0 && !pageLoading">
          <b-alert show variant="success" dismissible>
            No payments have been processed.
          </b-alert>
        </div>
        <div v-else-if="!pageLoading" class="col-xl-12 mb-0 content-box">
          <div class="pt-2 overflow-auto">
            <div class="col-xl-12 box-title border-bottom mb-3 ">
              <p class="mt-3">Showing {{ ((page - 1) * perPage) + 1 }} - {{ page * perPage > total ? total : page * perPage }} of {{ total.toLocaleString() }}</p>
            </div>
            <p class="text-right small">*To view payment in stripe, click on payment type</p>
            <table
              class="table table-striped table-bordered add-manage-table table demo footable-loaded footable"
              data-filter="#filter" data-filter-text-only="true">
              <thead>
                <tr>
                  <th>Order #</th>
                  <th>Task name</th>
                  <th>Price</th>
                  <th>Payment type</th>
                  <th>Buyer username</th>
                  <th>Buyer IP</th>
                  <th>Buyer join date</th>
                  <th>Seller username</th>
                  <th>Seller IP</th>
                  <th>Seller join date</th>
                  <th>Date Purchased</th>
                  <th>Date Completed</th>
                  <th>Date Processed</th>
                  <th colspan="2">Process Status</th>
                  <th>Processed By</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(payment, index) in result.results"  v-bind:key="index" class="mb-md-0 mb-2">
                  <td>
                    <router-link v-if="roleOrderDetails" class="text-dark" :to="'/admin/orders/' + payment.order_hash">{{ payment.order_hash }}</router-link>
                    <span v-else>{{ payment.order_hash }}</span>
                  </td>
                  <td>
                    <router-link class="text-dark" :to="'/' + payment.seller_username + '/' + payment.gig_slug">
                      {{ payment.gig_title }}
                    </router-link>
                  </td>
                  <td>${{(payment.order_price !== '0.00') ? payment.order_price : payment.order_subtotal}}</td>
                  <td>
                    <a v-if="payment.payment_gateway_hash === 'stripe'" class="text-dark" :href="'https://dashboard.stripe.com/payments/' +  payment.order_payment_reference" target="_blank" >
                      {{ payment.payment_gateway_name }}
                    </a>
                    <span v-else>{{ payment.payment_gateway_name }}</span>
                  </td>
                  <td>
                    <router-link class="text-dark" :to="'/admin/users/' + payment.buyer_hash">
                      {{ payment.buyer_username }}  <br> <small>Delayed: {{ payment.stats[0].buyer_username_delayed_payments }}</small>
                    </router-link>
                  </td>
                  <td>{{payment.buyer_ip}} <br> <small>Delayed: {{ payment.stats[0].buyer_ip_delayed_payments }}</small></td>
                  <td>{{ dateTimeMethod(payment.buyer_join_date) }}</td>
                  <td>
                    <router-link class="text-dark" :to="'/admin/users/' + payment.seller_hash">
                      {{ payment.seller_username }} <br> <small>Delayed: {{payment.stats[0].seller_username_delayed_payments}}</small>
                    </router-link>
                  </td>
                  <td>{{ payment.seller_ip }} <br> <small>Delayed: {{ payment.stats[0].seller_ip_delayed_payments }}</small></td>
                  <td>{{ dateTimeMethod(payment.seller_join_date) }}</td>
                  <td>{{ (new Date(payment.order_date_created)).toLocaleDateString() }}</td>
                  <td>{{ (new Date(payment.order_date_completed)).toLocaleDateString() }}</td>
                  <td>{{ (new Date(payment.process_date)).toLocaleDateString() }}</td>
                  <td>{{ payment.process_status === 1 ? 'Approved' : 'Declined' }}</td>
                  <td>{{ displayReason(payment.process_reference, payment.process_error) }}</td>
                  <td>{{ payment.process_by }}</td>
                </tr>
              </tbody>
            </table>

            <Pagination v-if="total > perPage" :perPage="perPage" :currentPage="page" :total="total" v-on:paginationUpdate="getPayments" class="mt-3"/>
          </div>
        </div>
        <div v-else class="mx-auto py-5">
          <b-spinner large label="Large Spinner"></b-spinner>
          <div>Loading Payments ...</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from '../../../apiService'
import Breadcrumb from '../../../components/Breadcrumbs/Breadcrumb'
import Pagination from '../../../components/Global/Pagination'

export default {
  name: 'AdminPaymentProcessed',
  components: { Pagination, Breadcrumb },
  data () {
    return {
      page: 1,
      perPage: 25,
      total: 0,
      pageLoading: true,
      result: [],
      roleOrderDetails: this.$store.getters.hasRole('ROLE_ADMIN_VIEW_ORDER_DETAILS')
    }
  },
  mounted () {
    this.getPayments()
  },
  methods: {
    getPayments (page = 1) {
      this.page = page
      this.pageLoading = true
      apiService.getDelayedPaymentsProcessedAdmin(this.page, this.perPage).then(r => {
        this.result = r.data.data
        this.page = this.result.pagination.page
        this.perPage = this.result.pagination.per_page
        this.total = this.result.pagination.total
        this.pageLoading = false
      }).catch(() => {
      })
    },
    dateTimeMethod (date) {
      if (date === null) {
        return 'NA'
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString() + ' ' + newDate.toLocaleTimeString()
    },
    displayReason (reference, error) {
      if (reference !== null && reference.substring(0, 5) !== 'user-') {
        return reference
      }

      return error
    }
  }
}
</script>

<style lang="scss" scoped>
  .content-box {
    overflow: unset !important;
  }
  .table {
    font-size: 10px;
    overflow-x: auto;
    @include md-up {
      overflow: hidden;
    }
    @include lg-up {
      font-size: 13px;
    }
  }
  .container {
    max-width: 100%;
  }
</style>
