<template>
  <b-pagination-nav v-if="type === 'link'"
    v-model="page"
    no-page-detect
    :link-gen="linkGen"
    :limit="maxPageOverride ? maxPageOverride : maxDisplayedPages"
    :number-of-pages="numberOfPages"
    aria-controls="my-table"
    align="center"
    @input="updateData(page)"
    use-router>
  </b-pagination-nav>
  <b-pagination v-else
    v-model="page"
    :limit="maxPageOverride ? maxPageOverride : maxDisplayedPages"
    :total-rows="total"
    :per-page="perPage"
    aria-controls="my-table"
    align="center"
    class="pagination"
    @input="updateData(page)"
  ></b-pagination>
</template>

<script>
import config from '../../config'
export default {
  name: 'Pagination',
  props: {
    perPage: {
      type: [Number, String],
      required: true
    },
    currentPage: {
      type: [Number, String],
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    params: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    },
    maxPageOverride: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      page: this.currentPage,
      numberOfPages: 1,
      maxDisplayedPages: config.task_results.max_displayed_pages
    }
  },
  mounted () {
    this.setPages()
  },
  methods: {
    updateData (page) {
      this.$emit('paginationUpdate', page)
    },
    linkGen (pageNum) {
      return {
        path: (this.params !== undefined) ? this.$route.path + this.params : this.$route.path,
        query: {
          page: pageNum
        }
      }
    },
    setPages () {
      let pages = this.total / this.perPage
      this.numberOfPages = Math.ceil(pages)
    }
  }
}
</script>

<style>
  .page-item {
    font-size: 0.8rem;
    padding: 10px 6px;
  }
  .page-item.disabled {
    display: none;
  }
</style>
